h1{
  font-size: 40px;
  line-height: 120%;
  font-weight: 600;
  color: $black;
}

h2{
  @extend h1;
  font-size: 33px;
}

h3{
  @extend h1;
  font-size: 33px;
}

h4{
  @extend h1;
  font-size: 27px;
}

h5{
  @extend h1;
  font-size: 24px;
}

h6{
  @extend h1;
  font-size: 21px;
}

p{
  font-size: 15px;
  font-weight: 400;
  line-height: 180%;
}