.login-page{
  width: 100%;
  min-height: 100vh;
  background-color: $lightgrey;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo{
    display: block;
    width: fit-content;
    margin: 0 auto;
    text-align: center;

    img{
      display: block;
      margin: 0 auto;
      max-width: 200px;
      width: 100%;
    }
  }

  .login-container{
    display: block;
    width: 100%;

    .form-layout{
      width: 100%;
      padding: 25px;

      .login-form{
        width: 100%;
      }
    }

    .recaptcha-layout{
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      padding: 10px 0;
      border-radius: 5px;
      background-color: #f9f9f9;
    }
  }

  .disclaimer{
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    font-size: 13px;
    text-align: center;
  }
}