.content-max{
  max-width: $maxWidth;
  margin-left: auto;
  margin-right: auto;
}

.ant-form-item{
  margin-bottom: 10px;
}

.site-layout .site-layout-background {
  background: $white;
}

.logo-top{
  padding: 20px 25px 17px 25px;
  transition: all .67s ease-in-out;

  img{
    transition: all .67s ease-in-out;
    display: block;
    width: 100%;
    max-width: 300px;
    max-height: 40px;
  }
}

.site-header{
  background: $white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px !important;
}

.site-page-header{
  margin: 0 0 20px 0 !important;
  padding: 0 !important;
}

.loading-global{
  width: 100%;
  max-height: 100vh;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .loading-icon{
    font-size: 34px;
    color: rgba($black, .2);
  }
}