.image-tool{
  --bg-color: #cdd1e0;
  --front-color: #388ae5;
  --border-color: #e8e8eb;

  .image-tool__image{
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 10px;

    &-picture {
      max-width: 100%;
      vertical-align: bottom;
      display: block;
    }

    &-preloader {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-size: cover;
      margin: auto;
      position: relative;
      background-color: var(--bg-color);
      background-position: center center;

      &:after {
        content: "";
        position: absolute;
        z-index: 3;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid var(--bg-color);
        border-top-color: var(--front-color);
        left: 50%;
        top: 50%;
        margin-top: -30px;
        margin-left: -30px;
        animation: image-preloader-spin 2s infinite linear;
        box-sizing: border-box;
      }
    }
  }

  .image-tool__image, .image-tool__caption{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .image-tool__image-picture{
    width: 100%;
  }

  &--withBorder {
    .image-tool__image {
      border: 1px solid $black;
    }
  }

  &--withBackground {
    .image-tool__image {
      padding: 15px;
      background: rgba($black, .1);

      &-picture {
        max-width: 50%;
        margin: 0 auto;
        display: block;
      }
    }
  }

  &--imagetext {
    .image-tool__image{
      position: relative;
  
      &:after{
        z-index: 10;
        border-radius: 3px;
        background: rgba($black, .7);
        color: $white;
        padding: 5px 10px;
        font-size: 10px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        position: absolute;
        content: "Image + Text Centered";
        top: 20px;
        left: 20px;
        text-transform: uppercase;
      }
    }
  }

  &--parallax {
    .image-tool__image{
      position: relative;
  
      &:before{
        z-index: 10;
        border-radius: 3px;
        background: rgba($black, .7);
        color: $white;
        padding: 5px 10px;
        font-size: 10px;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        position: absolute;
        content: "Parallax";
        top: 20px;
        right: 0;
        left: 0;
        margin: auto;
        width: fit-content;
        text-transform: uppercase;
      }
    }
  }

  &--stretched {
    .image-tool__image {
      &-picture {
        width: 100%;
      }
    }
  }

  &__caption {
    &[contentEditable="true"][data-placeholder]:before {
      position: absolute !important;
      content: attr(data-placeholder);
      color: #707684;
      font-weight: normal;
      display: none;
    }

    &[contentEditable="true"][data-placeholder]:empty {
      &:before {
        display: block;
      }

      &:focus:before {
        display: none;
      }
    }
  }

  &__textarea {
    &[contentEditable="true"][data-placeholder]:before {
      position: absolute !important;
      content: attr(data-placeholder);
      color: #707684;
      font-weight: normal;
      display: none;
    }

    &[contentEditable="true"][data-placeholder]:empty {
      &:before {
        display: block;
      }

      &:focus:before {
        display: none;
      }
    }
  }

  &--empty {
    .image-tool__image {
      display: none;
    }
  }

  &--empty,
  &--loading {
    .image-tool__caption {
      display: none;
    }
  }

  &--filled {
    .cdx-button {
      display: none;
    }

    .image-tool__image {
      &-preloader {
        display: none;
      }
    }
  }

  &--loading {
    &__image {
      min-height: 200px;
      display: flex;
      border: 1px solid var(--border-color);
      background-color: #fff;

      &-picture {
        display: none;
      }
    }

    .cdx-button {
      display: none;
    }
  }
}

.ce-header{
  margin: 0;
  padding: 5px 0;
  font-weight: 600;
}

@keyframes image-preloader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}