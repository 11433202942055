// @tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

// Colors
$white: #FFFFFF;
$black: #000000;
$lightgrey: #F3F4F5;

$maxWidth: 1040px;

// Breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;

// Globals
$transitionClassic: all .17s ease-in-out;

html, body{
  overflow-x: hidden;
  box-sizing: border-box;
  -webkit-font-smoothing: antialised;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.bgr-grey{
  background-color: $lightgrey;
}

.container{
  padding-left: 20px;
  padding-right: 20px;
  &.max-content{
    max-width: 1060px;
    margin: 0 auto;
  }
}

.loading-global{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;

  .loading-icon{
    font-size: 25px;
  }
}

@import './components/typography';
@import './components/layout';
@import './components/login';
@import './components/article';
@import './components/editorjs';