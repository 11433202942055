.article-edit-sideblock{
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: rgba(#f0f2f5, .4);
  border: 1px solid #f0f2f5;

  .extra-radio{
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    flex-direction: row !important;
    align-items: center !important;

    .ant-form-item-control{
      align-items: flex-end;
    }
  }
}

.article-edit-form{
  .ant-form-item{
    margin-bottom: 15px;

    .ant-form-item-label{
      padding-bottom: 5px;
      label{
        font-size: 13px;
        font-weight: 500;
      }
    }
  }
}

.stories-edit-form{
  .ant-form-item{
    margin-bottom: 15px;

    .ant-form-item-label{
      padding-bottom: 5px;
      label{
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .stories-slide-container{
    width: 100%;
    aspect-ratio: 9/16;
    background: rgba($lightgrey, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: $transitionClassic;

    &:hover{
      .slide-delete-btn{
        visibility: visible;
        opacity: 1;
      }
    }

    .slide-delete-btn{
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
      visibility: hidden;
      opacity: 0;
    }

    .slide-img, .slide-video{
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .slide-uploader{
      width: 100%;
      height: 100%;
      margin: 0;

      .ant-form-item-control-input{
        height: 100%;
        .ant-form-item-control-input-content{
          height: 100%;
        }
      }
    }
  }
}

.divider-base{
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.codex-editor{
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgba(240, 242, 245, 0.4);
}

.offline-msgbox{
  max-width: 400px;
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
  
  .msg{
    box-shadow: 0px 5px 10px -5px rgba(36, 8, 13, .2);
  }
}