.inner {
  display: flex;
  flex-direction: column;
  gap: 15px;

  > input {
    border: 1px solid #e8e8eb;
    box-shadow: inset 0 1px 2px #232c480f;
    border-radius: 3px;
    padding: 10px 12px;
    outline: none;
    width: 100%;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    &--item {
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 8px;

      > img {
        width: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      
      > button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        background-color: white;
        cursor: pointer;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        line-height: 1;
        border-radius: 3px;
        font-weight: 300;
      }

      > h5 {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}